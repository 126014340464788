:root {
    --cover-height: 90vh;
}


.cover-container {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}
 .cover-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;     
}

.btn-more {
    font-size: 18px;
    border-bottom: solid 1px gray;
    display: inline-block;
    vertical-align: middle;
}

.btn-more:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 10;
}

.notice-card{
    cursor: pointer;
}