.productionYearSelection {
    margin: 20px 0 15px 0;
    align-items: center;
}

.arrow {
    color: var(--haras-secondary);
    cursor: pointer;
}

.arrow:hover {
    color: #007bff;
}

.right {
    text-align: right;

}

.left {
    text-align: left;
}

.buttons_years {
    text-align: center;
    margin: 0 0 1rem 0;
}

.buttons_years button {
    border: 1px solid var(--haras-secondary) !important;
    color: var(--haras-secondary) !important;
    border: none;
    background-color: transparent !important;
    padding: 6px 12px 6px 12px;
    margin: 5px;
}

.buttons_years .active,
.buttons_years button:hover {
    background-color: var(--haras-secondary) !important;
    color: var(--text-white-color) !important;
}

.production_name{
    cursor: pointer;
}

.production_name:hover {
    color: #adbfd2;
}