.error_section{
    margin: 10rem auto 2rem auto;
    font-size: 16px;
    text-align: center;
    font-style: italic;
}

.error_section h1{
    font-size: 10rem;
    font-style: normal;
}

.error_section h6{
    margin: 0 0 0 2.5rem;
}