.haras-title {
  letter-spacing: 5px;
  font-weight: bold;
  font-size: 34px;
}

.haras-history-image-container {
  display: flex;
  justify-content: center;
}

.haras-history-image {
  width: 100%;
  margin: auto;
  display: block;
  text-align: center;
}

.haras-history-image>img {
  width: 90%;
  border-radius: 5px;
}

.haras-map {
  width: 100%;
  height: 400px;
  padding: 0 !important;
}

.haras-gallery-image {
  max-height: 800px !important;
  min-height: 800px !important;
}