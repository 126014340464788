.height-auto {
    height: auto !important;
}

.header-contenedor {
    align-items: center;
    flex-wrap: none !important;
}

.header-logo {
    padding: 0 0 20px 0;
    align-items: top center !important;
}

.header-logo:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

.header nav {
    width: 80%;
}

.header-nav-ul {
    display: flex;
    justify-content: left;
    text-decoration: none;
    list-style: none;
}

.header-nav-ul li {
    padding: 20px;
}

.header-burguer {
    padding: 0 0 10px 0;
    position: absolute;
    top: 30px;
    right: 50px;
    cursor: pointer;
    display: none;
}

@media (max-width: 984px) {
    .header nav {
        width: 60%;
    }

    .header-nav-ul {
        position: fixed;
        right: 0px;
        top: var(--header-height);
        height: 100%;
        background-color: rgb(254, 254, 254);
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 55%;
        display: flex;
        transform: translateX(100%);
        transition: transform 0.4s ease-in;
    }

    .header-burguer {
        display: block !important;
    }

    .header-burguer:hover {
        color: #adbfd2 !important;
    }

    .header-logo {
        padding: 0 0 20px 20px;
    }
}

.header-nav-ul-active {
    transform: translateX(0%);
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

header {
    height: var(--header-height);
}

.item-menu {
    letter-spacing: 0.5px;
    color: var(--secondary);
}

.item-menu:hover,
.item-menu-active {
    color: #adbfd2 !important;
    text-decoration: none;
}