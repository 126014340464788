footer {
    background-color: var(--haras-secondary);
    min-height: 400px;
    color: var(--text-white-color);
}

.footer-map {
    border: 1px solid var(--text-white-color);
    width: 100%;
    height: calc(100% - 32px);
}

.btn-footer-contact {
    color: var(--text-white-color) !important;
    border: var(--text-white-color) 1px solid !important;
}

.btn-footer-contact:hover {
    color: var(--text-black-color) !important;
    background-color: var(--text-white-color) !important;
    font-weight: bold !important;
}

.m-contact{
    margin-top: 0 !important;
}

.social-icons a{
    color: var(--text-white-color) !important;
    text-decoration: none !important;
}

.social-icons a:hover {
    font-weight: 550;
}

@media (max-width: 984px) {
    .m-contact{
        margin-top: 2rem !important;
    } 
}