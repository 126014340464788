.notice-container {
    border: 1px solid gray;
    min-height: 200px;
}

.card{
    margin: 20px auto 10px;
    width: 360px;
    height: 600px;
    border: none !important;
    perspective: 1000px;
}

.card_inner{
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
}

.card_inner.is-flipped{
    transform: rotateY(180deg);
}

.card_face{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.card_face-front{
    background-image: url(assets/img/yegua1.jpg);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_face-front h2{
    color: #363435;
    font-size: 32px;
}

.card_face-back{
    transform: rotateY(180deg);
}

.card_content{
    width: 100%;
    height: 100%;
}

.card_header{
    position: relative;
    height: 70%;
    padding: 30px 30px 40px;
    background-image: url(assets/img/yegua3.jpg);
    background-size: cover;
    border-bottom-right-radius: 50%;
}

.card_body{
    padding: 30px;
}

.card_body h3{
    color: #363435;
    font-size: 24px;
    font-weight: 600px;
    margin-bottom: 15px;
}

.card_body p{
    color: #363435;
    font-size: 18px;
    line-height: 1.5;
}