.card{
    width: 330px !important;
    height: 440px !important;
    border-radius: 8px;
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); */
    overflow: hidden;
    margin: 10px;
    transition: all 0.25s;
    cursor: pointer;
}

.card:hover{
    transform: translateY(-15px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.card img{
    width: 330px;
    height: 220px;
}

.card h4{
    font-weight: 500;
    margin: 1rem 0 0 1rem;
}

.card div{
    padding: 0 1rem;
    font-size: 16px;
    font-weight: 500;
}

.hr_card{
    width: 80%;
    margin: 1rem auto 1rem auto !important;
}

.country_icon{
    width: 20px !important;
    height: 20px !important;
}
