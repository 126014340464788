@font-face {
  font-family: "Raleway";
  src: url("assets/font/Raleway/static/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("assets/font/Raleway/static/Raleway-Light.ttf");
  font-weight: lighter;
}

@font-face {
  font-family: "Raleway";
  src: url("assets/font/Raleway/static/Raleway-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Raleway";
  src: url("assets/font/Raleway/static/Raleway-Italic.ttf");
  font-style: italic;
}

* {
  font-family: "Raleway", "PT Sans", sans-serif;
}

.my-6{
  margin: 5rem 0 0 0 !important;
}

:root {
  /* Colores de la aplicacion */
  --haras-primary: #f9ebd3 !important;
  --haras-secondary: #363435;
  /* Colores de texto */
  --text-white-color: #f8f2e8;
  --text-black-color: #363435;
  --text-gray-color: #a9a8a8;
  /* Medidas de componentes */
  --header-height: 80px;
}

.content-app {
  margin-top: 2rem;
}

.border-radius-5 {
  border-radius: 5px;
}

.text-bold {
  font-weight: bold !important;
}

.title-small {
  font-size: 14px;
  color: var(--text-gray-color);
  border-bottom: solid 1px var(--text-gray-color);
}

.page-item.active .page-link {
  background-color: var(--haras-secondary) !important;
  border-color: var(--haras-secondary) !important;
  color: var(--text-white-color) !important;
}

.page-link {
  color: var(--haras-secondary) !important;
}

.body{
  min-height: 70vh;
}

.title{
  margin-top: calc(var(--header-height) + 2rem)
}

.more{
  margin-top: 1rem;
  cursor: pointer;
}

.social-icons{
  padding: 1rem 1rem 0 0;
}

.spinner_loading {
  width: 3rem;
  height: 3rem;
  position: fixed;
  z-index: 100;
  top: 90px;
  left: 48%;
}

.container-title{
  align-items: flex-end;
}

.search-container{
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0.5rem 0;
  align-items: center;
}

.search-container input{
  border: none;
  margin: 0 0 0 5px;
}