@media (max-width: 768px) { 
    .data{
        margin: 20px 0 0 0 !important;
        padding: 0 !important;
    }
}

.data{
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
}